export default {
    async getAll(employeeUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/emergency_contacts`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch {
            return [];
        }
    },

    async create(employeeUuid, formData) {
        await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/emergency_contacts`, {
            relation:  formData.relation,
            name:      formData.name,
            surname:   formData.surname,
            telephone: formData.telephone,
            email:     formData.email,
            street:    formData.street,
            post_code: formData.post_code,
            city:      formData.city,
            note:      formData.note,
        });
    },

    async update(employeeUuid, emergencyContactId, formData) {
        await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/emergency_contacts/${emergencyContactId}`, {
            relation:  formData.relation,
            name:      formData.name,
            surname:   formData.surname,
            telephone: formData.telephone,
            email:     formData.email,
            street:    formData.street,
            post_code: formData.post_code,
            city:      formData.city,
            note:      formData.note,
        });
    },

    destroyURL(employeeUuid, emergencyContactId) {
        return `${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/emergency_contacts/${emergencyContactId}`;
    },
};
