export default {
    async getAll(clientId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/emergency_contacts`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: emergency_contacts.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async create(clientId, formData) {
        try {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/emergency_contacts`, {
                relation:  formData.relation,
                name:      formData.name,
                surname:   formData.surname,
                telephone: formData.telephone,
                email:     formData.email,
                street:    formData.street,
                post_code: formData.post_code,
                city:      formData.city,
                note:      formData.note,
            });
        } catch (error) {
            console.log('🚀 ~ file: emergency_contacts.api.js ~ create ~ error', error);
            throw error;
        }
    },

    async update(clientId, emergencyContactId, formData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/emergency_contacts/${emergencyContactId}`, {
                relation:  formData.relation,
                name:      formData.name,
                surname:   formData.surname,
                telephone: formData.telephone,
                email:     formData.email,
                street:    formData.street,
                post_code: formData.post_code,
                city:      formData.city,
                note:      formData.note,
            });
        } catch (error) {
            console.log('🚀 ~ file: emergency_contacts.api.js ~ update ~ error', error);
            throw error;
        }
    },

    destroyURL(clientId, emergencyContactId) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/emergency_contacts/${emergencyContactId}`;
    },
};
