<template>
    <div class="w-full card" style="max-width: 800px">
        <div class="border-b pb-4 mb-4">
            <p>{{ formData.name }} {{ formData.surname }}</p>
        </div>
        <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="px-52">
            <el-form-item :label="$t('clients.relation')" prop="relation">
                <el-input v-model="formData.relation" />
            </el-form-item>
            <el-form-item :label="$t('clients.name')" prop="name">
                <el-input v-model="formData.name" />
            </el-form-item>
            <el-form-item :label="$t('clients.surname')" prop="surname">
                <el-input v-model="formData.surname" />
            </el-form-item>
            <el-form-item :label="$t('clients.telephone')" prop="telephone">
                <el-input v-model="formData.telephone" />
            </el-form-item>
            <el-form-item :label="$t('clients.email')" prop="email">
                <el-input v-model="formData.email" />
            </el-form-item>
            <el-form-item :label="$t('clients.street')" prop="street">
                <el-input v-model="formData.street" />
            </el-form-item>
            <el-form-item :label="$t('clients.post_code')" prop="post_code">
                <el-input v-model="formData.post_code" />
            </el-form-item>
            <el-form-item :label="$t('clients.city')" prop="city">
                <el-input v-model="formData.city" />
            </el-form-item>
            <el-form-item :label="$t('clients.note')">
                <el-input
                    v-model="formData.note"
                    type="textarea"
                    :rows="3"
                />
            </el-form-item>
        </el-form>
        <div class="flex justify-end border-t pt-4 mt-6">
            <el-button type="danger" @click="handleDelete">
                {{ $t('common.delete') }}
            </el-button>
            <el-button type="primary" :loading="$wait.is(`updating.${person.id}`)" @click="update">
                {{ $t('common.update') }}
            </el-button>
        </div>
    </div>
</template>
<script>
import Api from './emergency_contacts.api';

export default {
    props: {
        person: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            formData: this.person,
            rules:    {
                name:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                surname:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                telephone: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                email:     [{ type: 'email', message: this.$t('common.wrong_format'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start(`updating.${this.person.id}`);

            try {
                await Api.update(this.person.employee_id, this.person.id, this.formData);
                this.$notify.success({ title: this.$t('common.success') });
            } finally {
                this.$wait.end(`updating.${this.person.id}`);
            }
        },

        handleDelete() {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(this.person.employee_id, this.person.id),
                actionType: 'destroy',
            });
        },
    },
};
</script>
